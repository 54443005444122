import React from 'react'
import servicesStyles from './services.module.scss'

const Services = (props) => {

    return (
        <section className={servicesStyles.services}>
        
            <div className={"container " + servicesStyles.services__container}>
                {props.children}
            </div>

            <div className={servicesStyles.services__waves}>
                <div className={servicesStyles.services__waves__wave}></div>
            </div>

        </section>
    )
}

export default Services