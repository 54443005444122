import React, { useState } from 'react'
import { Link } from 'gatsby'
import chatStyles from './chat.module.scss'

const Chat = () => {

    return (
        <section className={chatStyles.chat}>

            <div className={"container " + chatStyles.chat__container}>

                <div className={chatStyles.chat__text}>
                
                    <h2>A new level of support</h2>

                    <p>Take knowledgeable staff who are great at keeping your website online and combine them with web designers and developers, that takes our support to a whole new level.</p>
                    
                    <p>Managed means managed, ask us for help on setting up an email to fixing a script or making your site's code better, maybe you have no coding experience but need to migrate your site away from another provider, we have you covered.</p>

                    <Link to="/solutions/" className="button button--small button__blue">View Our Solutions</Link>
                
                </div>

                <div className={chatStyles.chat__box}>

                    <ul>
                        <li><p>Hey, can I get another email jenny@ setup on my account?</p></li>
                        <li><p>Hi Forest! No problem, please hold while I set that up for you</p></li>
                        <li><p>That's all setup, I've emailed all the details to you, anything else I can help you with?</p></li>
                        <li><p>Yes, my google speed score is low, are you able to look at that for me?</p></li>
                        <li><p>Sure! I'll take a look at your site now and see what improvements you can make, would you like me to email or call you?</p></li>
                        <li><p>That's great, emails fine</p></li>
                    </ul>

                </div>

            </div>

        </section>
    )
}

export default Chat