import React from 'react'

import { Link } from 'gatsby'
import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import Services from '../components/services'
import Chat from '../components/chat'
import Reviews from '../components/reviews'
import Logos from '../components/logos'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Index = () => {

    return (
        <Store>
            <Head title="Managed Website Hosting" />
            <Navigation />
            <Header large>

                <h1 className="hintro">Focus on your website <span>We'll do the hosting.</span></h1>

                <p>We're a <span>managed</span> hosting provider specialising in <span>cloud technology,</span> here to advise, build and manage your perfect solution.</p>

                <ul>
                    <li><Link to="/why/" className="button button--large button__blue">Discover the Difference <i className="icon icon-right-small"></i></Link></li>
                    <li><Link to="/solutions/" className="button button--large button__orange">Get Started <i className="icon icon-right-small"></i></Link></li>
                </ul>
            </Header>
            <Services>
                <ul>
                    <li>
                        <div>
                            <i className="icon icon-cloud"></i>
                            <h2>Cloud</h2>
                        </div>
                        <p>Managed cPanel Hosting</p>
                        <p>A powerful, affordable introduction to the cloud with high availability</p>

                        <p>From &pound;17.95/mo</p>

                        <Link to="/cloud-hosting/" className="button button--small button__blue">Cloud Hosting <i className="icon icon-right-small"></i></Link>
                    </li>
                    <li>
                        <div>
                            <i className="icon icon-wordpress"></i>
                            <h2>WordPress</h2>
                        </div>
                        <p>Designed for WordPress</p>
                        <p>Lightning-fast hosting environments utalising LiteSpeed</p>

                        <p>From &pound;17.95/mo</p>

                        <Link to="/wordpress-hosting/" className="button button--small button__blue">WordPress Hosting <i className="icon icon-right-small"></i></Link>
                    </li>
                    <li>
                        <div>
                            <i className="icon icon-lightbulb"></i>
                            <h2>Solutions</h2>
                        </div>
                        <p>Dedicated Consultant</p>
                        <p>Creating unique solutions for your websites needs</p>

                        <p>Free Consultation</p>

                        <Link to="/solutions/" className="button button--small button__blue">Solutions <i className="icon icon-right-small"></i></Link>
                    </li>
                </ul>
            </Services>
            <Chat/>
            <Reviews/>
            <Logos/>
            <Talk/>
            <Footer/>
            <Cookies/>
        </Store>
    )
}

export default Index
